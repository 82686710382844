<template>
    <div class="home-content-wrapper">
        <div class="content-list">
            <router-view></router-view>
        </div>
        <div class="content-fixed">
            <ContentFixed></ContentFixed>
        </div>
        
    </div>
</template>
<script>
import ContentFixed from "./contentFixed.vue"
export default{
    name: 'homeContent',
    components: {
        ContentFixed
    }
}
</script>
<style lang="less" scoped>
.home-content-wrapper{
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: calc(100vh - 112px);
    text-align: center;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .content-list{
        width: 800px;
        box-sizing: border-box;
    }
    .content-fixed{
        width: 320px;
        box-sizing: border-box;
    }
}
</style>