<template>
  <div class="home-wrapper">
    <div class="home-header">
      <HomeHeader ref="homeHeader"></HomeHeader>
    </div>
    <div class="home-content">
      <HomeContent ref="homeContent"></HomeContent>
    </div>
    <div class="home-footer">
      <HomeFooter ref="homeFooter"></HomeFooter>
    </div>
    <el-backtop target=".home-content"></el-backtop>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHeader from '@/components/homeHeader'
import HomeContent from '@/components/homeContent'
import HomeFooter from '@/components/homeFooter'

export default {
  name: 'HomeView',
  components: {
    HomeHeader, HomeContent, HomeFooter
  },
  data(){
    return{

    }
  },
  mounted(){
    let headerDom = document.querySelector('.home-header')
    let fixedDom = document.querySelector('.content-fixed')
    let width = document.querySelector('.content-list').offsetWidth
    window.addEventListener("mousewheel", function(event) {
      // 处理滚轮事件

      // 处理滚动时顶部菜单显示隐藏动画
      // deltaY是正数为下滑
      // deltaY是负数为上滑
      if(event.deltaY > 0){
        headerDom.style.top = "-56px"
      }else{
        headerDom.style.top = "0px"
      }
      // 处理页面滚动时右侧内容固定
      if(window.pageYOffset >= fixedDom.offsetTop){
        fixedDom.style.position = "fixed"
        fixedDom.style.top = "0"
        fixedDom.style.marginLeft = width + 20 + 'px'
      }else{
        fixedDom.style.position = ""
        fixedDom.style.top = ""
        fixedDom.style.marginLeft = ""
      }
      // 处理滚动到回顶部时右侧内容归位
      if(window.scrollY <= 200){
        fixedDom.style.position = ""
        fixedDom.style.top = ""
        fixedDom.style.marginLeft = ""
      }
    });
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.home-wrapper{
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to right, #fdfdfd, #d5abd7 50%, #fbfbfb);
  // background-image: url("@/assets/img/background.png");
  // background-repeat: no-repeat;
  position: relative;
  .home-header{
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: #fff;
    transition: all 0.6s ease;
  }
  .home-content{
    box-sizing: border-box;
    width: 1140px;
    min-height: calc(100vh - 112px);
    margin: 0 auto;
    margin-top: 56px;
  }
  .home-footer{
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    background-color: #443f3f;
  }
}
</style>
