import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/list',
    children: [
      {path: '/list', name: 'list', component: ()=>{return import("@/components/homeContent/contentList.vue")}},
      {path: '/detail', name: 'detail', component: ()=>{return import("@/components/homeContent/contentDetail.vue")}},
      {path: '/abilityDetail', name: 'abilityDetail', component: ()=>{return import("@/components/homeContent/abilityDetail.vue")}}
    ]
  },
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
